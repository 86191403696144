import img1 from '../assets/Images/Gallery/gallery-1.jpg';
import img2 from '../assets/Images/Gallery/gallery-2.jpg';
import img3 from '../assets/Images/Gallery/gallery-3.jpg';
import img4 from '../assets/Images/Gallery/gallery-4.jpg';
import img5 from '../assets/Images/Gallery/gallery-5.jpg';
import img6 from '../assets/Images/Gallery/gallery-6.jpg';
import img7 from '../assets/Images/Gallery/gallery-7.jpg';
import img8 from '../assets/Images/Gallery/gallery-8.jpg';
import img9 from '../assets/Images/Gallery/gallery-9.jpg';
import img10 from '../assets/Images/Gallery/gallery-10.jpg';

const images = [
  {
    url: img1, 
    alt: ""
  },
  {
    url: img2, 
    alt: ""
  },
  {
    url: img3, 
    alt: ""
  },
  {
    url: img4, 
    alt: ""
  },
  {
    url: img5, 
    alt: ""
  },
  {
    url: img6, 
    alt: ""
  },
  {
    url: img7, 
    alt: ""
  },
  {
    url: img8, 
    alt: ""
  },
  {
    url: img9, 
    alt: ""
  },
  {
    url: img10, 
    alt: ""
  },
]

export default images;