const breakpoints = {
  "phone": 320,
  "phoneM": 375,
  "phoneL": 426,
  "tablet": 768,
  "SMdesktop": 1024,
  "desktop": 1441,
  "LGdesktop": 1920,
  "4Kdesktop": 2560,
}

export default breakpoints;